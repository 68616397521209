import React, { useEffect, useState } from 'react'
import { CssBaseline, ThemeProvider, Theme, Stack, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import * as settingsActions from '../../store/settings/settings.actions'
import Env from '../../shared/config/envConfig'
import appConfig from '../../shared/config/appConfig'
import editConfig from '../../shared/config/editConfig'
import { getAppTheme } from '../../shared/config/getAppTheme'

// Import CRUDE
import { CrudeConfiguration } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import SnackbarComponent from '../../shared/components/MessageComponents/SnackbarComponent'
import { Message } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { Route, Switch, useHistory } from 'react-router'
import { useCrude, CrudeContext } from '@3m5/crude-frontend/dist/crudeHooks'
import TableActionButtons from './TableActionButtons'
import { getUser, getPrefersDarkMode } from '../../store/settings/settings.selectors'
import SwitchComponent from '../../shared/components/FormComponents/SwitchComponent'
import Login from '../../shared/components/Login'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(getUser)

  const [logout, setLogout] = useState<boolean>(false)
  const [darkMode, setDarkMode] = useState<boolean>(useSelector(getPrefersDarkMode))
  const [theme, setTheme] = useState<Theme>(getAppTheme(darkMode))
  const appName = 'admin_console'

  const basePath = Env.basePath
  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Accept', 'application/json')
  requestHeaders.set('Content-Type', 'application/json')

  useEffect(() => {
    const restUrl = `${basePath}/api/session/user`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        dispatch(settingsActions.setUser(json))
      })
      .catch(err => console.log(err))
  },
  [],
  )

  const _onLogout = () => {
    const restUrl = `${basePath}/api/session/logout`
    fetch(restUrl,
      {
        method: 'POST',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => dispatch(settingsActions.setUser(undefined)))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    const oldMode = localStorage.getItem('darkMode') === 'true'
    setTheme(getAppTheme(darkMode))
    if (oldMode !== darkMode) {
      localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
    }
  },
  [darkMode],
  )

  useEffect(() => {
    logout && _onLogout()
  }, [logout],
  )

  useEffect(() => {
    let pathname = `${appName}/Country`
    if (!user) {
      pathname = 'login'
    }
    history.push(`/${pathname}`)
  }, [history, user],
  )

  const crudeConfiguration: CrudeConfiguration = {
    theme: theme,
    path: `/${appName}`,
    api: {
      app: appName,
      restPath: `${Env.crudeRestBase}/api/crude/`,
    },
    appLayout: appConfig(),
    editLayout: editConfig(),
    tabs: [
      {
        entity: 'Country',
      },
      {
        entity: 'Region',
      },
      {
        entity: 'Accommodation',
      },
      {
        entity: 'Activity',
      },
      {
        entity: 'ActivityBlock',
      },
      {
        entity: 'JourneyDay',
      },
      {
        entity: 'Journey',
      },
      {
        entity: 'Customer',
      },
      {
        entity: 'Offer',
      },
      {
        entity: 'Invoice',
      },
      {
        entity: 'Booking',
      },
      {
        entity: 'Event',
      },
      {
        entity: 'Task',
      },
      {
        entity: 'ContactNote',
      },
      {
        entity: 'User',
      },
    ],
  }

  window.addEventListener('CrudeMessages', (({ detail }: CustomEvent) => {
    const message: Message = detail.message
    dispatch(settingsActions.setMessage(message))
  }) as EventListener)

  window.addEventListener('CrudeLogout', (({ detail }: CustomEvent) => {
    setLogout(true)
  }) as EventListener)

  const onChangeFile = (e: any) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const url = `${basePath}/api/customer/import/`
    fetch(url,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: files[0],
      })
  }

  const hiddenFileInput = React.useRef(null)

  const importDoorFile = () => {
    hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.click()
  }
  const crudeContext = useCrude({ appPageProps: crudeConfiguration, tableActionComponent: TableActionButtons })
  return (
    <>
      <CrudeContext.Provider value={crudeContext}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Stack spacing={2} sx={{ width: '100%' }}>
            <div className='main'>
              {/* einmaliger import > soll nicht sichtbar sein, nur bei Bedarf angeschalten werden */}
              {false &&
                <> <Button variant='outlined' color='primary' onClick={importDoorFile}>Import</Button>
                  <input
                    type='file'
                    ref={hiddenFileInput}
                    onInput={onChangeFile}
                    style={{ display: 'none' }}
                  />
                </>}
              {user &&
                <>
                  <SwitchComponent
                    label='Dark Mode'
                    checked={darkMode}
                    onChange={(checked: boolean) => {
                      setDarkMode(checked)
                      dispatch(settingsActions.setPrefersDarkMode(checked))
                    }}
                    name='checkedA'
                    color='primary'
                  />
                  <Button onClick={_onLogout}>Logout</Button>
                </>}
              <Switch>
                <Route exact path='/login' component={Login} />
                <Route path='/' component={crudeContext.Crude} />
              </Switch>
            </div>
            {TableActionButtons}
            <SnackbarComponent />
          </Stack>
        </ThemeProvider>
      </CrudeContext.Provider>
    </>
  )
}

export default App
