import { createTheme, Theme, ThemeOptions } from '@mui/material'

export function getAppTheme (darkMode?: boolean): Theme {
  const lightTheme = {
    typography: {
      fontSize: 12,
    },
    overrides: {
      MuiAutocomplete: {
        option: {
          '&[data-focus="true"]': {
            color: '#084d8c',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            border: '2px solid',
            borderColor: '#084d8c',
          },
          '&$focused $notchedOutline': {
            borderColor: '#084d8c',
            borderWidth: 1,
          },
        },
      },
      MuiTabs: {
        root: {
          backgroundColor: '#f5f5f5',
          borderTop: '2px solid',
          borderColor: '#ebe7e9',
        },
      },
      MuiTab: {
        root: {
          '&$selected': {
            backgroundColor: '#ffffff',
            borderTop: '3px solid',
            borderColor: '#084d8c',
            color: '#084d8c',
          },
        },
      },
      MuiButton: {
        root: {
          border: '1px solid #084d8c',
          color: '#ffffff',
          backgroundColor: '#084d8c',
          minWidth: '0',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#084d8c',
            border: '1px solid #084d8c',
          },
        },
        filledSecondary: {
          backgroundColor: '#4C4C4C',
          border: '1px solid #4C4C4C',
          '&:hover': {
            backgroundColor: '#383a49',
            border: '1px solid #383a49',
          },
        },
        filledPrimary: {
          color: '#ffffff',
          backgroundColor: '#084d8c',
          border: '1px solid #084d8c',
          '&:hover': {
            backgroundColor: '#084d8c',
            border: '1px solid #084d8c',
          },
        },
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            border: 'none',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          '&:hover': {
            color: '#084d8c',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#114279',
      },
      secondary: {
        main: '#4C4C4C',
      },
    },
  }
  const darkTheme: ThemeOptions = {
    typography: {
      fontSize: 12,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            border: '1px solid #ff9800',
            minWidth: '0',
            boxShadow: 'none',
          },
          text: {
            color: '#ff9800',
          },
        },
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffb74d',
        light: 'rgba(255, 255, 255, 0.5)',
        dark: '#121212',
      },
      grey: {
        A100: 'transparent',
        A200: 'rgba(255, 255, 255, 0.08)',
      },
      secondary: {
        main: '#ff9800',
      },
      background: {
        default: '#121212',
        paper: '#39465c',
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.5)',
        secondary: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }

  const theme: Theme = createTheme(darkMode ? darkTheme : lightTheme)

  return theme
}
