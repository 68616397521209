import { createSelector } from 'reselect'
import { SettingsRootState } from '../root.reducer'
import { Theme } from '@mui/material'

export const getPrefersDarkMode = createSelector(
  (state: SettingsRootState) => state.settingsStore.prefersDarkMode,
  (state: boolean) => state,
)

export const getAppLayout = createSelector(
  (state: SettingsRootState) => state.settingsStore.appLayout,
  (state) => state,
)

export const getEditLayout = createSelector(
  (state: SettingsRootState) => state.settingsStore.editLayout,
  (state) => state,
)

export const getTheme = createSelector(
  (state: SettingsRootState) => state.settingsStore.theme,
  (state: Theme) => state,
)

export const getCrudeAppName = createSelector(
  (state: SettingsRootState) => state.settingsStore.crudeAppName,
  (state: string | undefined) => state,
)

export const getMessage = createSelector(
  (state: SettingsRootState) => state.settingsStore.message,
  (state) => state,
)

export const getUser = createSelector(
  (state: SettingsRootState) => state.settingsStore.user,
  (state) => state,
)
