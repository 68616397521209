import {
  EditLayout, StringLayout,
} from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = (): EditLayout => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Activity',
        columns: 2,
        lines: 5,
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'name',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'region',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'subName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'description',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'layoutType',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'entranceFree',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'isHighlight',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'picturesIds',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
        ],
        formGroups: [
          {
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 5,
              },
            },
            name: 'general',
            initialOpen: true,
            closable: false,
          },
        ],
      },
      {
        entity: 'Task',
        columns: 1,
        lines: 6,
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'processor',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'description',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'state',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'creationDate',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'dueDate',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
        ],
        formGroups: [
          {
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 6,
              },
            },
            name: 'general',
            initialOpen: true,
            closable: false,
          },
        ],
      },
      {
        entity: 'ContactNote',
        columns: 1,
        lines: 5,
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'text',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'date',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'customer',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'user',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
        ],
        formGroups: [
          {
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 5,
              },
            },
            name: 'general',
            initialOpen: true,
            closable: false,
          },
        ],
      },
      {
        entity: 'Customer',
        columns: 2,
        lines: 12,
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'titel',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'formOfAddress',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'firstName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'lastName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'birthDay',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'birthPlace',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'street',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'zipCode',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'country',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'city',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'email',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'telephoneNumber',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'extraTelephoneNumber',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'nationality',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'passportNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'passportUntil',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'travelInsurance',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'information',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'description',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'extraInformation',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'imageFileIds',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'documentFileIds',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
        ],
        formGroups: [
          {
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 6,
              },
            },
            title: {
              'de-DE': 'Allgemeine Informationen',
            },
            name: 'general',
            initialOpen: true,
            closable: false,
          },
          {
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 6,
              },
            },
            name: 'address',
            title: {
              'de-DE': 'Kontaktdaten',
            },
            initialOpen: true,
            closable: false,
          },
          {
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 10,
              },
            },
            name: 'additional',
            title: {
              'de-DE': 'Weitere Informationen',
            },
            initialOpen: false,
            closable: true,
          },
          {
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
            name: 'files',
            title: {
              'de-DE': 'Anhänge',
            },
            initialOpen: false,
            closable: true,
          },
        ],
      },
      {
        entity: 'Journey',
        columns: 2,
        lines: 5,
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'name',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'startDay',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'journeyPriceDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 2,
              },
            },
          },
          {
            name: 'serviceDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'mealDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'hotelDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'tourGuideDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'visaDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'extraExpensesDescription',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
        ],
        formGroups: [
          {
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 5,
              },
            },
            name: 'general',
            initialOpen: true,
            closable: false,
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
