import React from 'react'
import { Button } from '@mui/material'
import { EntityData } from '@3m5/crude-frontend/dist/shared/types/dataTypes'
import Env from '../../shared/config/envConfig'
import { useTranslation } from 'react-i18next'

interface InjectedTableActionComponentProps {
  entity: string
  entityId: string
  treeViewLevel: number
  entityData: EntityData
  sharedData: Record<string, any>
}
const TableActionButtons: React.FC<InjectedTableActionComponentProps> = (props) => {
  const basePath = Env.basePath
  const { t } = useTranslation()

  const exportWord = () => {
    const url = `${basePath}/api/export/${props.entityId}`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = `Angebot-${props.entityData.name}.docx`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }

  const exportBooking = () => {
    const url = `${basePath}/api/journey/export/${props.entityId}`
    props.entityId && fetch(url,
      {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = `Buchungsliste-${props.entityData.name}.xlsx`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }

  const copyJourney = () => {
    const url = `${basePath}/api/journey/duplicate/${props.entityId}`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
      })
      .then(response => response.json())
      .then(json => {
        window.location.reload()
      })
  }

  return (
    <>
      {props.entity === 'Offer' &&
        <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={() => exportWord()}>{t('common:downloadOffer')}</Button>}
      {props.entity === 'Journey' &&
        <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={() => copyJourney()}>{t('common:copyJourney')}</Button>}
      {props.entity === 'Journey' &&
        <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={() => exportBooking()}>{t('common:exportBooking')}</Button>}
    </>
  )
}
export default TableActionButtons
